// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/barlow/Barlow-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
  
  /** Ionic CSS Variables **/
:root {
  /** font-family **/
  --ion-font-family: 'Barlow';

  /** primary **/
  --ion-color-primary: #1b3c6a;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: white;
  --ion-color-primary-tint: #af5e53;

  /** secondary **/
  --ion-color-secondary: #1ab0bc;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #005096;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #00a542;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #db6a00;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium-dark **/
  --ion-color-medium-dark: #6b6d76;
	--ion-color-medium-dark-rgb: 107,109,118;
	--ion-color-medium-dark-contrast: #ffffff;
	--ion-color-medium-dark-contrast-rgb: 255,255,255;
	--ion-color-medium-dark-shade: #5e6068;
	--ion-color-medium-dark-tint: #7a7c84;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: white;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: hsl(220, 100%, 99%);
  --ion-color-light-tint: #f5f6f9;

  --placeholder: #798DA8;
  --ion-color-line: #18345C;

  #imgwrap {
    min-width: 50px;
    min-height: 50px;
    margin: 0 auto;

    background-color: transparent;
    text-align: center !important;
    img {
      border-bottom-width: 10px;
      border: 10px;
      border-color: black;
      border-radius: 50%;
    }
  }
}

.ion-color-medium-dark {
	--ion-color-base: var(--ion-color-medium-dark);
	--ion-color-base-rgb: var(--ion-color-medium-dark-rgb);
	--ion-color-contrast: var(--ion-color-medium-dark-contrast);
	--ion-color-contrast-rgb: var(--ion-color-medium-dark-contrast-rgb);
	--ion-color-shade: var(--ion-color-medium-dark-shade);
	--ion-color-tint: var(--ion-color-medium-dark-tint);
}

/* Remove bottom border on md */
.header-md::after {
  background-image: none;
}

/* Remove bottom border on ios */
.header-ios ion-toolbar:last-child {
  --border-width: 0;
}

ion-toolbar {
  background-color: var(--ion-color-light) !important;
  color: var(--ion-color-light) !important;

  div {
    img {
      height: 45px;
    }
  }
}

.logo-indienov-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logo-indienov {
  margin-top: 0;
  margin-bottom: 0;
}

ion-list {
  --ion-background-color: var(--ion-color-primary);
}

select {
  background-color: var(--ion-color-primary);
  border-color: transparent !important;
  outline: none;
}

ion-item::part(native) {
  color: var(--ion-color-light);
  background-color: var(--ion-color-primary);
}

ion-searchbar {
  .searchbar-input-container {
    .searchbar-input {
      border-radius: 30px !important;
    }
  }
}

ion-card {
  width: 100%;
  min-height: 100%;
  border-top-left-radius: 69px;
  border-top-right-radius: 69px;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
}

ion-button.cta-button {
  width: 100%;
  min-height: 2.5rem;
  max-width: 20rem;
  margin-top: 50px;
  border-radius: 90px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: min(3.5vw, 14px);
  white-space: normal;
  text-align: center;
  word-wrap: break-word;
  text-transform: uppercase;
}

.googleAutocomplete {
  outline: none;
  margin-top: 10px;
  background-color: var(--ion-color-primary) !important;
  border: none;
  width: 100%;
  display: block;
  &.ion-valid {
    border-bottom: 2px solid var(--ion-color-success) !important;
  }

  &.ion-invalid {
    border-bottom: 2px solid var(--ion-color-danger) !important;
  }
}

ion-content {
  ion-card {
    ion-card-content {
      ion-list {
        ion-list-header {
          margin-top: -10px;
          margin-bottom: 10px;
          ion-label {
            font-size: 20px;
            color: var(--ion-color-light) !important;
          }
        }
        ion-item {
          margin-left: 10px;
          --ion-item-border-color: var(--ion-color-line); 
          ion-label {
            color: var(--ion-color-light) !important;
          }
        }
      }
    }
  }
}

ion-button {
  ion-label {
    font-weight: 600;
  }
}

.secondary-button {
  width: 80%;
  max-width: 20rem;
  min-height: 2.5rem;
  border-radius: 90px;
  margin: 10px auto;
  display: block;
  color: white;
  font-size: min(3.5vw, 14px);
  background-color: #18355e;
  &:hover {
    background-color: #153054;
  }
}

.hide-password-icon {
  font-size: large;
  color: var(--ion-color-primary);
}

ion-item {
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

ion-label {
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
}

ion-input {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
}

span {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
}

input::placeholder {
  opacity: 1 !important;
  color: var(--placeholder) !important;
}

textarea::placeholder {
  opacity: 1 !important;
  color: var(--placeholder) !important;
}


ion-input::part(placeholder) {
  opacity: 1 !important;
  color: var(--placeholder) !important;
}

ion-select::part(placeholder) {
  opacity: 1 !important;
  color: var(--placeholder) !important;
}

ion-input[type="date"] input::-webkit-datetime-edit {
    color: var(--placeholder) !important;
    opacity: 1 !important;
}

ion-input[type="date"] input::-moz-placeholder {
  color: var(--placeholder) !important;
  opacity: 1 !important;
}

ion-input[type="date"] input::-ms-input-placeholder {
  color: var(--placeholder) !important;
  opacity: 1 !important;
}

ion-input[type="date"] input::placeholder {
  color: var(--placeholder) !important;
  opacity: 1 !important;
}

ion-label[position="stacked"].form-title {
  all: unset !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
  font-weight: bold !important;
  font-size: medium !important;
  display: block !important;
  overflow-wrap: anywhere !important;
  max-width: 100%;
  margin-top: 5px !important;
  margin-bottom: -5px !important;
}